import React from 'react'
import { Link } from 'gatsby'

const FourOhFour = () => {

    return (
        <div>
            <Link to="/">Home</Link>
            <h1>404 - The page you were looking for could not be found.</h1>
        </div>
    )
}

export default FourOhFour